@tailwind base;
@tailwind components;
@tailwind utilities;


/* Floating animation for hero image */
@keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  .animate-float {
    animation: float 4s ease-in-out infinite;
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .animate-fade-in {
    animation: fadeIn 2s ease-in-out forwards;
  }
  
  /* Fade-in with upward motion */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .animate-fade-in-up {
    animation: fadeInUp 2s ease-out forwards;
  }
  
  /* Slide-in animation */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .animate-slide-in {
    animation: slideIn 1s ease-in-out forwards;
  }
  
  /* Pulse animation for CTA button */
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }
  .animate-pulse {
    animation: pulse 2s infinite;
  }
  

  /* Add this CSS to your global styles or in the component's style tag */

@keyframes slideInDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.notification-bar {
  animation: slideInDown 0.5s ease-out;
}

.fade-out {
  animation: fadeOut 0.5s ease-out forwards;
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateY(-2px); }
  20%, 40%, 60%, 80% { transform: translateY(1px); }
}

.animate-slow-shake {
  animation: shake 1.5s ease-in-out infinite;
  position: relative; /* Ensure the element is positioned relative to avoid affecting layout */
}
